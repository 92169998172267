import { PATH } from '../../../../constants/global'
import { TABLE } from '../../../../constants/tables'
import { renderDateOrNothing } from '../../../../common/utils/functions'

export const BASIC_TABLE = {
   basicPath: PATH.AUDIT_HISTORY,
   downloadPath: PATH.AUDIT_HISTORY.DOWNLOAD,
   downloadCsvText: 'Export to Excel',
   extraClassName: 'table-basic',
   header: 'Title', // TBA
   key: 'ExportAuditItems', // TBA
   modalTitle: 'Download Excel File',
   placeholder: ' note history',
   tableTitle: TABLE.TITLE.NOTES_HISTORY,
   primaryActionText: 'Export',
   secondaryActionText: 'Cancel',
   exportFileName: 'NoteHistory.xlsx',
   columnHeaders: [
      { Header: 'Audit Title', accessor: 'AuditTitle', minWidth: 150 },
      {
         Header: 'Audit Sent Date',
         accessor: 'DateSent',
         align: 'center',
         minWidth: 150,
         Cell: renderDateOrNothing,
      },
      {
         Header: 'Audit Due Date',
         accessor: 'DateDue',
         align: 'center',
         minWidth: 150,
         Cell: renderDateOrNothing,
      },
      {
         Header: 'Audit Status',
         accessor: 'AuditStatusDesc',
         align: 'center',
         minWidth: 130,
      },
      {
         Header: 'Audited By',
         accessor: 'AuditAssignedToUser',
         align: 'center',
         minWidth: 150,
      },
      {
         Header: 'Total Audit Items',
         accessor: 'ItemTotal',
         align: 'center',
         minWidth: 120,
      },
      {
         Header: 'Total Audit Unconfirmed',
         accessor: 'ItemsUnconfirmed',
         align: 'center',
         minWidth: 120,
      },
      {
         Header: 'Total Audit Confirmed',
         accessor: 'ItemsConfirmed',
         align: 'center',
         minWidth: 120,
      },
      {
         Header: 'Audited Item Title',
         accessor: 'Title',
         maxWidth: 250,
         minWidth: 200,
      },
      {
         Header: 'Audit Confirmed',
         accessor: 'IsConfirmed',
         align: 'center',
         minWidth: 100,
      },
      {
         Header: 'Audit Quantity',
         accessor: 'AuditQuantity',
         align: 'center',
         minWidth: 100,
      },
      {
         Header: 'Confirmation Type',
         accessor: 'Confirmation',
         align: 'center',
         minWidth: 100,
      },
      {
         Header: 'Condition',
         accessor: 'ConditionDesc',
         align: 'center',
         minWidth: 100,
      },
      {
         Header: 'Audit Note',
         accessor: 'AuditNote',
         maxWidth: 250,
         minWidth: 200,
      },
      {
         Header: 'Item Type',
         accessor: 'ToolTypeDesc',
         align: 'center',
         minWidth: 150,
      },
      {
         Header: 'Description',
         accessor: 'Description',
         maxWidth: 250,
         minWidth: 200,
      },
      {
         Header: 'Manufacturer',
         accessor: 'ManufacturerName',
         align: 'center',
         minWidth: 150,
      },
      {
         Header: 'Model No.',
         accessor: 'ModelNumber',
         align: 'center',
         minWidth: 150,
      },
      {
         Header: 'Serial No.',
         accessor: 'SerialNumber',
         align: 'center',
         minWidth: 120,
      },
      {
         Header: 'Barcode',
         accessor: 'ItemBarcode',
         align: 'center',
         minWidth: 120,
      },
      {
         Header: 'Category',
         accessor: 'CategoryName',
         align: 'center',
         minWidth: 150,
      },
      {
         Header: 'Item Status',
         accessor: 'ItemStatusDesc',
         align: 'center',
         minWidth: 120,
      },
      {
         Header: 'Item Status Quantity',
         accessor: 'StatusQuantity',
         align: 'center',
         minWidth: 120,
      },
      {
         Header: 'Item Total Quantity',
         accessor: 'TotalQuantity',
         align: 'center',
         minWidth: 120,
      },
      {
         Header: 'Loaned/Pending To',
         accessor: 'AssignedToUser',
         align: 'center',
         minWidth: 150,
      },
      {
         Header: 'Assigned To Kit',
         accessor: 'AssignedToKitName',
         align: 'center',
         minWidth: 150,
      },
      {
         Header: 'Purchase Date',
         accessor: 'PurchaseDate',
         align: 'center',
         minWidth: 120,
         Cell: renderDateOrNothing,
      },
      {
         Header: 'Purchase Price',
         accessor: 'PurchasePrice',
         align: 'center',
         minWidth: 120,
      },
      {
         Header: 'Warranty Date',
         accessor: 'WarrantyDate',
         align: 'center',
         minWidth: 150,
         Cell: renderDateOrNothing,
      },
      { Header: 'Rental Tool', accessor: 'IsRentalTool', minWidth: 150 },
      {
         Header: 'Rental Start Date',
         accessor: 'RentalDate',
         align: 'center',
         minWidth: 150,
         Cell: renderDateOrNothing,
      },
      {
         Header: 'Rental End Date',
         accessor: 'RentalReturnDate',
         align: 'center',
         minWidth: 150,
         Cell: renderDateOrNothing,
      },
      {
         Header: 'Rate-Day',
         accessor: 'CustomRentalRate1',
         align: 'center',
         minWidth: 100,
      },
      {
         Header: 'Rate-Week',
         accessor: 'CustomRentalRate2',
         align: 'center',
         minWidth: 100,
      },
      {
         Header: 'Rate-Month',
         accessor: 'CustomRentalRate3',
         align: 'center',
         minWidth: 100,
      },
      {
         Header: 'Rate-Hour',
         accessor: 'CustomRentalRate4',
         align: 'center',
         minWidth: 100,
      },
      {
         Header: 'Custom Date 1',
         accessor: 'CustomDate1Value',
         align: 'center',
         minWidth: 130,
         Cell: renderDateOrNothing,
      },
      {
         Header: 'Custom Text 1',
         accessor: 'CustomText1Value',
         align: 'center',
         minWidth: 130,
      },
      {
         Header: 'Custom URL 1',
         accessor: 'CustomURL1Value',
         align: 'center',
         minWidth: 130,
      },
      {
         Header: 'Custom Date 2',
         accessor: 'CustomDate2Value',
         align: 'center',
         minWidth: 130,
         Cell: renderDateOrNothing,
      },
      {
         Header: 'Custom Text 2',
         accessor: 'CustomText2Value',
         align: 'center',
         minWidth: 130,
      },
      {
         Header: 'Custom URL 2',
         accessor: 'CustomURL2Value',
         align: 'center',
         minWidth: 130,
      },
      { Header: 'Website URL', accessor: 'WebsiteUrl', minWidth: 180 },
      { Header: 'Owners Manual URL', accessor: 'AttachmentUrl', minWidth: 150 },
      {
         Header: 'Return Warehouse',
         accessor: 'DefaultLocation',
         minWidth: 200,
      },
   ],
   initialPayload: {
      searchForAuditItem: '',
      sentStartDate: null,
      sentEndDate: null,
      selectedConditions: [],
      selectedConfrmationType: [],
      selectedItemConfirmation: 0,
      offset: -1,
      selectedConnections: [],
      selectedLocations: [],
      selectedCategories: [],
      selectedStatus: [],
      selectedManufacturers: [],
      skip: 0,
      take: 10,
   },
   downloadRequest: {
      searchForAuditItem: '',
      sentStartDate: null,
      sentEndDate: null,
      selectedConditions: [],
      selectedConfrmationType: [],
      selectedItemConfirmation: 0,
      offset: -1,
      selectedConnections: [],
      selectedLocations: [],
      selectedCategories: [],
      selectedStatus: [],
      selectedManufacturers: [],
      skip: 0,
      take: 0,
   },
}
