import React from 'react'

import NotesHistory from '../../components/views/history/notesHistory/notesHistory'
import { LayoutLoggedIn } from '../../components/views/layout'

export default function NoteHistoryPage() {
   return (
      <LayoutLoggedIn>
         <NotesHistory />
      </LayoutLoggedIn>
   )
}
