import React, { useRef, useState, useMemo, useEffect } from 'react'
import * as yup from 'yup'
import { format } from 'date-fns'
import { isEmptyObject } from '../../../../common/utils/functions'
import { TableWrapper } from '../../../common/table/TableWrapper'
import {
   Button,
   CsvDownloadForm,
   Filters,
   LoadingOverlay,
   Modal,
   PageHeader,
   Search,
   SlidePanel,
   Svg,
} from '../../../common'
import FilterButton from '../../../common/filters/filterButton/filterButton'
import FilterTags from '../../../common/filters/filterTags/filterTags'
import {
   DateRangeFilter,
   DropdownFilter,
} from '../../../common/filters/filterTypes'
import { Option } from '../../../common/form/fields/dropdown/dropdown.type'
import { convertNoteTypesToOptions } from '../../../common/filters/helpers'
import { PAGINATION } from '../../../../constants/tables'
import { FORMATS } from '../../../../constants/datetime'
import { BASIC_TABLE } from './constants'
import * as styles from './notesHistory.module.scss'
import { useHistory, useFilters, useNoteTypes } from '../../../../hooks'
import {
   Filters as FiltersObject,
   FiltersForAPI,
} from '../../../../hooks/useFilters/useFilters.type'
import { flattenFilterObj } from '../../../../hooks/useFilters/useFilters.helpers'

const {
   extraClassName,
   placeholder,
   tableTitle,
   downloadCsvText,
   basicPath,
   key,
   modalTitle,
   primaryActionText,
   secondaryActionText,
   exportFileName,
   columnHeaders,
   initialPayload,
   downloadRequest,
} = BASIC_TABLE

const schema = yup.object().shape({
   startDate: yup.date().max(new Date(), 'Start Date cannot be in the future'),
   endDate: yup.date().when(['startDate'], (startDate: Date) => {
      if (startDate) {
         return yup
            .date()
            .min(startDate, 'End Date cannot be earlier than the Start Date')
            .max(new Date(), 'End Date cannot be in the future')
      }
   }),
})

export function formatFiltersForAPI(filters: FiltersObject): FiltersForAPI {
   function formatDate(date: Date) {
      return format(date, FORMATS.filterDateAPI)
   }

   const startDateFilter = {
      ...(filters?.sentStartDate?.value instanceof Date && {
         startDate: formatDate(filters?.sentStartDate?.value),
      }),
   }
   const endDateFilter = {
      ...(filters?.sentEndDate?.value instanceof Date && {
         endDate: formatDate(filters?.sentEndDate?.value),
      }),
   }

   return {
      ...flattenFilterObj(filters),
      ...startDateFilter,
      ...endDateFilter,
   }
}

const NotesHistory = () => {
   const { query, skip, take } = PAGINATION
   const {
      data: itemData,
      list,
      setPath,
      setPageOptions,
      totalItems,
      isLoading,
      isRefetching,
      // @ts-ignore
   } = useHistory({
      skip,
      take,
      query,
      searchForAuditItem: '',
      sentStartDate: undefined,
      sentEndDate: undefined,
      selectedConditions: [],
      selectedConfrmationType: [],
      selectedItemConfirmation: 0,
      offset: 0,
      selectedConnections: [],
      selectedLocations: [],
      selectedCategories: [],
      selectedStatus: [],
      selectedManufacturers: [],
   })

   const [searchMode, setSearchMode] = useState(false)
   const [pageSized, setPageSized] = useState(PAGINATION.pageSized)
   const [isPageReset] = useState(false)
   const [searchCriteria, setSearchCriteria] = useState('')
   const [fullDownload] = useState(downloadRequest)
   const [_filteredDownload, setFilteredDownload] = useState(downloadRequest)
   const [isDownloading, setIsDownloading] = useState(false)

   const {
      errors,
      modify,
      reset,
      remove: removeFilter,
      save,
      savedFilters,
      tmpFilters,
   } = useFilters(schema, {})

   const buttonRef = useRef<HTMLInputElement>(null)

   const [filtersOpen, setFiltersOpen] = useState(false)
   const [filterValues, setFilterValues] = useState({})
   const formRef = useRef<HTMLFormElement>()
   const [isModalVisible, setIsModalVisible] = useState(false)

   // filter data
   const { list: noteTypes } = useNoteTypes()

   const applySavedFilters = (filters: FiltersObject) => {
      setFiltersOpen(false)
      handleGetItems(query, 0, pageSized, filters)
      setFilterValues(formatFiltersForAPI(filters))
   }

   const handleGetItems = (
      searchForAuditItem?: string,
      skip?: number,
      take?: number,
      filters?: FiltersObject
   ) => {
      const formattedFilters = formatFiltersForAPI(filters || savedFilters)
      setFilterValues(formattedFilters)

      list({
         searchForAuditItem: searchForAuditItem,
         sentStartDate: null,
         sentEndDate: null,
         selectedConditions: [],
         selectedConfrmationType: [],
         selectedItemConfirmation: 0,
         offset: -1,
         selectedConnections: [],
         selectedLocations: [],
         selectedCategories: [],
         selectedStatus: [],
         selectedManufacturers: [],
         skip: skip,
         take: take,
         ...formattedFilters,
      })
   }

   const handleClose = () => {
      setIsModalVisible(false)
      buttonRef.current.click()
   }

   const showDownloadModal = () => {
      setIsModalVisible(true)
   }

   const handleDropdownChange = (
      name: string,
      label: string,
      options: Option[]
   ) => {
      modify(
         name,
         label,
         options?.map((option) => ({
            label: option.label,
            value: option.value,
         }))
      )
   }

   const handleRemoveTag = (name: string, id: string): void => {
      const newFilters = removeFilter(name, id)
      applySavedFilters(newFilters)
      setFilterValues(formatFiltersForAPI(newFilters))
   }

   const handleFilterSave = async () => {
      const isSaved = await save()

      if (isSaved) {
         applySavedFilters(tmpFilters)
      }
   }

   const handleResetFilters = () => {
      formRef.current.reset()
      reset()
      applySavedFilters({})
   }
   const columns = useMemo(() => columnHeaders, [itemData])

   const noteTypeOptions = convertNoteTypesToOptions(noteTypes)

   useEffect(() => {
      setPath(basicPath, key)
      setPageOptions(initialPayload)
   }, [])

   useEffect(() => {
      setFilteredDownload({
         searchForAuditItem: searchCriteria,
         sentStartDate: null,
         sentEndDate: null,
         selectedConditions: [],
         selectedConfrmationType: [],
         selectedItemConfirmation: 0,
         offset: -1,
         selectedConnections: [],
         selectedLocations: [],
         selectedCategories: [],
         selectedStatus: [],
         selectedManufacturers: [],
         skip: 0,
         take: 10,
      })
   }, [searchCriteria])

   return (
      <div>
         {(isLoading || isRefetching) && <LoadingOverlay />}
         <PageHeader title={tableTitle}>
            <Button
               onClick={showDownloadModal}
               preserveText
               variant="plain"
               disabled={!Boolean(itemData.length)}
               minWidth="0"
            >
               <Svg id="download" width={8} height={8} /> {downloadCsvText}
            </Button>
         </PageHeader>

         <div className={styles.searchWrapper}>
            <div className={styles.searchWrapperSearch}>
               <Search
                  handleQuery={handleGetItems}
                  searchMode={searchMode}
                  setSearchMode={setSearchMode}
                  pageSized={pageSized}
                  placeHolder={placeholder}
                  setSearchCriteria={setSearchCriteria}
               />
            </div>
            <div className={styles.searchWrapperFilter}>
               <FilterButton
                  isActive={!isEmptyObject(savedFilters)}
                  onClick={() => setFiltersOpen(!filtersOpen)}
               />
            </div>
         </div>

         <FilterTags filters={savedFilters} onClick={handleRemoveTag} />

         <TableWrapper
            isLoading={isLoading}
            data={itemData}
            columns={columns}
            totalCount={totalItems}
            getItems={handleGetItems}
            takeItems={PAGINATION.take}
            skipItems={PAGINATION.skip}
            setSearchMode={setSearchMode}
            searchMode={searchMode}
            isPageReset={isPageReset}
            setPageSized={setPageSized}
            extraClassName={extraClassName}
            searchCriteria={searchCriteria}
         />

         <SlidePanel isOpen={filtersOpen} onClose={() => setFiltersOpen(false)}>
            <Filters onReset={handleResetFilters} onSave={handleFilterSave}>
               <form ref={formRef}>
                  <DropdownFilter
                     defaultValue={
                        tmpFilters?.selectedNoteTypes?.value as Option[]
                     }
                     isMulti
                     id="selectedNoteTypes"
                     onChange={handleDropdownChange}
                     options={noteTypeOptions}
                     title="Note type"
                  />

                  <DropdownFilter
                     defaultValue={
                        tmpFilters?.selectedStatuses?.value as Option[]
                     }
                     isMulti
                     id="selectedStatuses"
                     onChange={handleDropdownChange}
                     options={[]}
                     title="Status"
                  />

                  <DropdownFilter
                     defaultValue={tmpFilters?.completedBy?.value as Option[]}
                     id="completedBy"
                     onChange={handleDropdownChange}
                     options={[]}
                     title="Completed by"
                  />

                  <DateRangeFilter
                     dateProps={{
                        from: {
                           defaultValue: tmpFilters?.dueStartDate
                              ?.value as Date,
                           error: errors?.dueStartDate,
                           id: 'dueStartDate',
                           placeholder: 'Start date',
                        },
                        to: {
                           defaultValue: tmpFilters?.dueEndDate?.value as Date,
                           error: errors?.dueEndDate,
                           id: 'dueEndDate',
                           placeholder: 'End date',
                        },
                     }}
                     id="dueDate"
                     onChange={modify}
                     title="Due date"
                  />

                  <DateRangeFilter
                     dateProps={{
                        from: {
                           defaultValue: tmpFilters?.completedStartDate
                              ?.value as Date,
                           error: errors?.completedStartDate,
                           id: 'completedStartDate',
                           placeholder: 'Start date',
                        },
                        to: {
                           defaultValue: tmpFilters?.completedEndDate
                              ?.value as Date,
                           error: errors?.completedEndDate,
                           id: 'completedEndDate',
                           placeholder: 'End date',
                        },
                     }}
                     id="completedDate"
                     onChange={modify}
                     title="Completed date"
                  />
               </form>
            </Filters>
         </SlidePanel>

         <Modal
            isModalVisible={isModalVisible}
            closeModal={handleClose}
            title={modalTitle}
         >
            <CsvDownloadForm
               primaryActionText={primaryActionText}
               secondaryActionText={secondaryActionText}
               criteria={''}
               data={itemData}
               fileName={exportFileName}
               closeModal={handleClose}
               path={basicPath.DOWNLOAD}
               query={searchCriteria}
               fullDownload={fullDownload}
               filters={filterValues}
               setIsDownloading={setIsDownloading}
               isDownloading={isDownloading}
               defaultParams={downloadRequest}
               buttonRef={buttonRef}
            />
         </Modal>
      </div>
   )
}

export default NotesHistory
